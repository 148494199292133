import React, { useState } from "react";
import logoapp from "../../assets/images/invest-display-logo.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./style.scss";
import { useSelector } from "react-redux";

function Headertwo() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { pageSpecData } = useSelector((state) => state?.pages || {});

  const { t } = useTranslation();

  return (
    <nav className="navbar navbar-area navbar-area-2 navbar-expand-lg">
      <div className="container nav-container">
        <div className="responsive-mobile-menu">
          <button
            className={
              isMenuOpen
                ? "menu toggle-btn d-block d-lg-none open"
                : "menu toggle-btn d-block d-lg-none"
            }
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <span className="icon-left"></span>
            <span className="icon-right"></span>
          </button>
        </div>
        <div className="logo logoappmobile">
          <Link to="/">
            <img src={logoapp} alt="img" />
          </Link>
        </div>
        <div
          className={
            isMenuOpen
              ? "collapse navbar-collapse sopen"
              : "collapse navbar-collapse"
          }
          id="itech_main_menu"
        >
          <ul className="navbar-nav menu-open text-lg-end">
            <li className="menu-item-has-children">
              <Link to={"/"}>{t("navbar.home")}</Link>
            </li>
            <li>
              <Link to={"/about"}>{t("navbar.about")}</Link>
            </li>
            <li>
              <Link to={"/contactus"}>{t("navbar.contact")}</Link>
            </li>
            <li>
              <a href={pageSpecData?.link} rel="noopener noreferrer">
                Mon Compte
              </a>
            </li>
          </ul>
        </div>
        <div className="nav-right-part nav-right-part-desktop d-lg-inline-flex align-item-center">
          <Link
            style={{ height: "fit-content" }}
            className="btn btn-base border-radius-40"
            to="/checkout"
          >
            Acheter la formation
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default Headertwo;
