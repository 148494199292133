import Footer from "../footer";
import Header from "../header";
import Headertwo from "../headertwo";

const Component = ({
  component: Component,
  protect,
  parts,
  footer = true,
  ads,
  showheader,
  showfooter,
  homefooter,
  ...rest
}) => {

  const RenderComponent = (props) => {
    return (
      <>
        <Component {...props} />
      </>
    );
  };

  return (
    <>
      {showheader && <Headertwo />}
      <RenderComponent {...rest} />
      {showfooter && <Footer bleuone={homefooter ? true : false} />}
    </>
  );
};

export default Component;