import React from 'react'
import { ContactComp, EnteteComp } from '../../components'

function ContactPage() {
  return (
    <>
    <EnteteComp title={'Nous contacter'} content={'Contactez-Nous'} />
    <ContactComp />
    </>
  )
}

export default ContactPage