import React from 'react'
import { EnteteComp } from '../../components'
import './style.scss'

function AboutPage() {
  return (
    <div>
        <EnteteComp title={'À Propos'} content={'À Propos de nous'} />
        <div className="about-us container">
        <p>Nous sommes la société invest display, une plateforme en ligne dédiée à fournir des cours de qualité supérieure sur l'intelligence artificielle et les technologies connexes.</p>
        <p>Notre mission est de démocratiser l'accès à l'éducation en IA en offrant des cours accessibles à tous, des débutants aux experts, afin de permettre à chacun d'acquérir des compétences précieuses dans ce domaine en pleine expansion.</p>
        <p>Nos cours sont développés par des experts de l'industrie et des universitaires réputés, garantissant un contenu actualisé, pertinent et de haute qualité. Nous croyons en l'apprentissage pratique et concret, c'est pourquoi nos cours sont conçus pour être interactifs et engageants, offrant aux étudiants une expérience d'apprentissage enrichissante.</p>
        <p>Que vous soyez un professionnel cherchant à approfondir vos connaissances en IA, un étudiant souhaitant se préparer à une carrière dans ce domaine passionnant, ou simplement quelqu'un curieux d'en savoir plus sur l'IA, la société Invest display est là pour vous accompagner dans votre parcours d'apprentissage.</p>
        <p>Rejoignez-nous dès aujourd'hui et explorez le monde fascinant de l'intelligence artificielle avec la société Invest display !</p>
      </div>
    </div>
  )
}

export default AboutPage