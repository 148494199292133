import React, { useEffect, useState } from "react";
import "./style.scss";
import { Link, useLocation } from "react-router-dom";
import { apiClient } from "../../actions/api";
import { blue } from "@mui/material/colors";

function SuccessPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const firstName = searchParams.get("first_name");
  const lastName = searchParams.get("last_name");
  const email = searchParams.get("email");
  const city = searchParams.get("city");
  const country = searchParams.get("country");
  const phone = searchParams.get("phone");
  const courseId = searchParams.get("course_id");
  const courseLink = searchParams.get("course_link");
  const price = searchParams.get("price");
  const password = searchParams.get("password");

  const data = {
    first_name: firstName,
    last_name: lastName,
    email: email,
    city: city,
    country: country,
    phone: phone,
    course_id: courseId,
    course_link: courseLink,
    price: price,
    token: token,
    password: password,
  };

  const [loading, setLoading] = useState(true);
  const [tokenValid, setTokenValid] = useState(null);
  const [usermail, setEmail] = useState();
  const [username, setUser] = useState();
  const [userpass, setPass] = useState();
  const [formation, setFormation] = useState();
  const [note, setNote] = useState(null);

  useEffect(() => {
    setLoading(true);
    apiClient()
      .post(`/tokens/checkStatus`, { token: token })
      .then((res) => {
        if (res?.data?.success) {
          apiClient()
            .post(`/clients-store`, data)
            .then((res) => {
              setLoading(false);
              if (res.data.status) {
                setTokenValid(true);
                setEmail(res?.data?.email);
                setUser(res?.data?.username);
                setPass(res?.data?.password);
                setFormation(res?.data?.formationLink);
                setLoading(false);
              } else {
                setTokenValid(false);
                setLoading(false);
              }
            });
          setNote(false);
        } else {
          setTokenValid(false);
          setNote(true);
        }
      });
  }, []);

  useEffect(() => {
    if (note !== null) {
      setLoading(false);
    }
  }, [note]);

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <>
      {tokenValid != null ? (
        <div className="success-page">
          {note !== null && (note == true ? (
            <div className="card">
              <div
                style={{
                  borderRadius: "200px",
                  height: "200px",
                  width: "200px",
                  background: "rgb(255 234 233)",
                  margin: "0 auto",
                }}
              >
                <i className="crossmark">✗</i>
              </div>
              <h1 className="crossmark-title">Données invalides</h1>
              <p>
                Merci de vérifier votre e-mail <br /> si vous avez vraiment
                effectué le paiement.
              </p>
            </div>
          ) : (
            <div className="card">
              <div
                style={{
                  borderRadius: "200px",
                  height: "200px",
                  width: "200px",
                  background: "#F8FAF5",
                  margin: "0 auto",
                }}
              >
                <i className="checkmark">✓</i>
              </div>
              <h1>Paiement Réussi</h1>
              <p>
                Merci pour votre paiement !
                <br /> Voici vos identifiants pour vous connecter et vous
                inscrire au cours au cas où vous n'auriez pas reçu l'e-mail.
              </p>
              <div>Nom d'utilisateur: {username}</div>
              <div>Email: {usermail}</div>
              <div>Mot de passe : {userpass}</div>
              <div>
                Lien de la formation : <br />
                <br />
                <a
                  style={{
                    backgroundColor: "#0a2fc2",
                    color: "white",
                    border: "1px solid",
                    borderRadius: "4px",
                    padding: "10px",
                  }}
                  href={formation}
                >
                  Commencer Maintenant
                </a>
              </div>
            </div>
          ))}
        </div>
      ) : setLoading(true)}
    </>
  );
}

export default SuccessPage;
