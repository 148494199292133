import { PAGE_DATA, SPECIFIC_PAGE_DATA } from "../constants/actions";

const INITIAL_STATE = {
    pageData: [],
    pageSpecData: {},
};

function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
    case PAGE_DATA :
        return {...state,pageData:action.payload}
    case SPECIFIC_PAGE_DATA:
        return {...state,pageSpecData:action.payload}
    default:
        return state;
    }
}
export default reducer;