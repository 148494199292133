//GLOBAL
export const TOGGLE_LOADING ="TOGGLE_LOADING";
export const IS_SUBMITED  ="IS_SUBMITED";
//USER
export const AUTHENTICATE_USER = "AUTHENTICATE_USER"
export const PAGE_DATA = "PAGE_DATA"
export const SPECIFIC_PAGE_DATA = "SPECIFIC_PAGE_DATA"
export const FORM_DATA = "FORM_DATA"




