import React, { useEffect, useState } from 'react'
import { DeliveryInfo, PaimentCard, ShoppingCard } from '../../components'
import './style.scss'
import { apiClient } from '../../actions/api'

function CheckoutPage() {
  const [title, setTitle] = useState()
  const [description, setDescription] = useState()
  const [link, setLink] = useState()
  const [image, setImage] = useState()
  const [price, setPrice] = useState()
  
  useEffect(() => {
    apiClient()
      .get(`/mainone`)
      .then((res) => {
        if(res?.data?.success){
          const parsedTitle = JSON.parse(res.data?.data?.title);
          setDescription(res.data?.data?.description)
          setLink(res.data?.data?.link)
          setImage(res.data?.data?.image)
          setTitle(parsedTitle?.fr)
          setPrice(res.data?.data?.price)
        }
      });
  }, []);

  useEffect(() => {
    if(title){
      localStorage.setItem("titleC", title);
    }
  }, [title]);

  useEffect(() => {
    if(link){
      localStorage.setItem("linkC", link);
    }
  }, [link]);

  useEffect(() => {
    if(image){
      localStorage.setItem("imageC", image);
    }
  }, [image]);

  useEffect(() => {
    if(price){
      localStorage.setItem("priceC", price);
    }
  }, [price]);

  return (
    <div className='checkoutpage'>
        <ShoppingCard titleC={title} descriptionC={description} linkC={link} imageC={image} priceC={price}/>
        <div className='delv-paiment'>
          <DeliveryInfo linkC={link} priceC={price}/>
        </div>
    </div>
  )
}

export default CheckoutPage