import React from "react";
import "./style.scss";

function CguContent() {
  return (
    <div className="terms-and-conditions container">
      <p>
        Nous vous invitons à prendre connaissance des présentes conditions
        générales relatives à la vente en ligne par le site{" "}
        <strong>course-ai-influencer.com</strong>. La réalisation du paiement
        par vos soins vaut acceptation irrévocable de ces conditions.
      </p>
      <p>
      Nom de la société : Invest display <br />
      Adresse : AVENUE ALLAL EL FASSI COMPLEX EL HABSSI RESIDENTIEL ET PROFISIONNEL IMM A BUREAU N°07 MARRAKECH <br />
      Patante : 45311936<br />
      ICE : 003305770000021<br />
      RC : 145741<br />
      </p>
      <span>1. Objet</span>
      <p>
        Les présentes conditions générales de ventes ont pour objet de définir
        les droits et obligations des parties intervenant dans le cadre de la
        vente en ligne des cours proposés via le site internet
        www.course-ai-influencer.com
      </p>
      <span>2. Produits et Services</span>
      <p>
        Le Vendeur propose des cours en ligne sur le Site. Les caractéristiques
        principales des Cours, leur prix et les modalités de commande sont
        disponibles sur le Site.
      </p>
      <span>3. Commande</span>
      <p>
        Le Client peut passer commande des Cours sur le Site. La commande est
        considérée comme ferme et définitive après validation du paiement par le
        Client.
      </p>
      <span>4. Prix et Paiement</span>
      <p>
        Les prix des Cours sont indiqués sur le Site en Dirham et sont
        susceptibles d'être modifiés par le Vendeur à tout moment. Le paiement
        s'effectue en ligne par le moyen de paiement proposé sur le Site.
      </p>
      <span>5. Livraison</span>
      <p>
        Les Cours sont accessibles immédiatement après validation du paiement
        par le Client. Aucune livraison physique n'est effectuée.
      </p>
      <span>6. Droit de Rétractation</span>
      <p>
        Conformément à la législation en vigueur, le Client dispose d'un délai
        de rétractation de 14 jours à compter de la validation de sa commande
        pour exercer son droit de rétractation, sans avoir à justifier de motifs
        ni à payer de pénalités, à l'exception des frais de retour.
      </p>
      <span>7. Responsabilité</span>
      <p>
        Le Vendeur s'engage à fournir les Cours conformément aux présentes CGV.
        Toutefois, sa responsabilité ne saurait être engagée en cas de force
        majeure ou de dysfonctionnement technique indépendant de sa volonté.
      </p>
      <span>8. Données Personnelles</span>
      <p>
        Le Vendeur s'engage à protéger les données personnelles du Client
        conformément à sa politique de confidentialité disponible sur le Site.
      </p>
      <span>9. Propriété Intellectuelle</span>
      <p>
        Les Cours et tous les éléments du Site sont la propriété intellectuelle
        du Vendeur et sont protégés par les lois en vigueur sur le droit
        d'auteur et la propriété intellectuelle. Toute reproduction ou
        utilisation des Cours ou du Site sans autorisation préalable est
        strictement interdite.
      </p>
      <span>10. Litiges</span>
      <p>
        Les présentes CGV sont soumises au droit marocain. En cas de litige, les
        parties s'efforceront de trouver une solution à l'amiable. À défaut, les
        tribunaux marocains seront seuls compétents.
      </p>
      <span>11. Remboursement</span>
      <p>
        En cas d'annulation de commande par le Client avant le
        début du cours, un remboursement intégral sera effectué, sous réserve
        que la demande d'annulation soit reçue par le Vendeur au moins 48 heures
        avant le début prévu du cours. Pour toute annulation effectuée moins de
        48 heures avant le début du cours, aucun remboursement ne sera accordé.
        Le remboursement sera effectué dans un délai de 7 jours à compter de la
        réception de la demande d'annulation. Tout remboursement sera effectué
        selon le même mode de paiement que celui utilisé lors de l'achat
        initial.
      </p>
    </div>
  );
}

export default CguContent;
