import React, { useEffect } from "react";
import CryptoJS from 'crypto-js';

const PaywallPopup = ({ data }) => {
  // Constants for your paywall configuration
  const merchantAccount = 'Course_ai_influencer';
  const paywallSecretKey = '0hR9w3Zd5KeGkhbu';
  const paywallUrl = 'https://payment.payzone.ma/pwthree/launch';
  const notificationKey = 'dtZjXzjGaJfjnMJq';

  // Function to generate HMAC-SHA256 signature
  const generateSignature = (payload, secretKey) => {
    // Convert payload to JSON string
    const jsonPayload = JSON.stringify(payload);

    // Calculate the HMAC-SHA256 signature
    const hash = CryptoJS.HmacSHA256(jsonPayload, secretKey);

    // Convert the hash to a Base64-encoded string
    const signature = CryptoJS.enc.Base64.stringify(hash);

    return signature;
  };

  useEffect(() => {
    const openPaywall = async () => {
      try {
        const payload = {
          merchantAccount: merchantAccount,
          timestamp: Math.floor(Date.now() / 1000),
          skin: 'vps-1-vue',
          customerId: data.customerId,
          customerCountry: data.customerCountry,
          customerLocale: data.customerLocale,
          chargeId: Math.floor(Date.now() / 1000),
          price: data.amount,
          currency: 'MAD', // Assuming currency is fixed to MAD
          description: data.description,
          mode: 'DEEP_LINK',
          paymentMethod: 'CREDIT_CARD',
          showPaymentProfiles: 'true',
          flowCompletionUrl: data.flowCompletionUrl,
        };

        // Generate the signature
        const signature = generateSignature(payload, paywallSecretKey);

        // Open a new popup window for paywall
        const paywallWindow = window.open("", "paywallWindow", "width=600,height=400");

        if (paywallWindow) {
          paywallWindow.document.write(`
            <form id="openPaywall" action="${paywallUrl}" method="POST" target="_blank">
              <input type="hidden" name="payload" value='${JSON.stringify(payload)}' />
              <input type="hidden" name="signature" value="${signature}" />
            </form>
            <script type="text/javascript">
              document.getElementById("openPaywall").submit();
            </script>
          `);
        } else {
          throw new Error('Failed to open paywall window. Please ensure popups are enabled.');
        }
      } catch (error) {
        console.error('Paywall error:', error);
        // Handle error: show message to user or redirect
        alert('Failed to open paywall. Please try again later.');
        // Optionally, redirect user to a different page or handle error state
        window.location.href = '/error-page';
      }
    };

    openPaywall();
  }, [data]);

  return null; // No UI elements needed, handling everything in useEffect
};

export default PaywallPopup;