import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './style.scss';
import { apiClient } from '../../actions/api';

function ContactComp() {
  const { t } = useTranslation();

  // State for form data
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Handle form submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await apiClient().post('contact', formData);
      if(response?.data?.success){
        toast.success("Formulaire soumis avec succès!");
      }
    } catch (error) {
      toast.error("Une erreur s'est produite lors de la soumission du formulaire.");
    }
  };

  return (
    <div className="contact-area pd-top-100 pd-bottom-80 pb-4">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="section-title">
              <h2 className="title">{t('contact.subtitle')}</h2>
              <p className="content">{t('contact.content')}</p>
            </div>
          </div>
          <div className="col-lg-8">
            <form>
              <div className="row">
                <div className="col-md-6">
                  <div className="single-input-inner style-bg">
                    <input 
                      type="text" 
                      name="name" 
                      value={formData.name} 
                      onChange={handleChange} 
                      placeholder={t('contact.name_placeholder')} 
                      required 
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="single-input-inner style-bg">
                    <input 
                      type="email" 
                      name="email" 
                      value={formData.email} 
                      onChange={handleChange} 
                      placeholder={t('contact.email_placeholder')} 
                      required 
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="single-input-inner style-bg">
                    <input 
                      type="text" 
                      name="phone" 
                      value={formData.phone} 
                      onChange={handleChange} 
                      placeholder={t('contact.phone_placeholder')} 
                      required 
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="single-input-inner style-bg">
                    <textarea 
                      name="message" 
                      value={formData.message} 
                      onChange={handleChange} 
                      placeholder={t('contact.message_placeholder')} 
                      required 
                    />
                  </div>
                </div>
                <div className="col-12">
                  <button className="btn btn-base border-radius-5" onClick={handleSubmit} type="button">
                    {t('contact.button_text')}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ContactComp;