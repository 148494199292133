import React from 'react'
import './style.scss';
import Card from 'react-bootstrap/Card';
// import { coursesdata } from '../../helpers/fakedata';

function ShoppingCard({titleC, descriptionC, linkC, imageC, priceC}) {
  return (
    <div className='shopping-card'>
        <Card body> 
            <div className="shop-title">
                <span>Votre commande</span>
                <span>Sous total</span>
            </div>
            <div className="showlistshop">
                <div className="eachcourse">
                    <img src={imageC && `https://course-ai-influencer.com/gestion/public/images/${imageC}`} alt="image" />
                    <div className='description-part'>
                        <span>{titleC}</span>
                        <span>ID: {627823}</span>
                    </div>
                    <span className='price-part'>{priceC}</span>
                </div>
            </div>
            <div className="sub-delivery">
                <span>Sous total</span>
                <span>{priceC} MAD</span>
            </div>
            <div className="total">
                <span>Total</span>
                <span>{priceC} MAD</span>
            </div>
        </Card>
    </div>
  )
}

export default ShoppingCard