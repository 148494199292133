import React from "react";
import "./style.scss";
import backgroundFoot from "../../assets/img/bg/3.png";
// import logo2 from "../../assets/img/logo2.png";
import logo2 from "../../assets/images/invest-display-logo.png";
import box2 from "../../assets/images/BOX2.png";

import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../assets/css/style.css";
import CallIcon from "@mui/icons-material/Call";
import MailIcon from "@mui/icons-material/Mail";
import PlaceIcon from "@mui/icons-material/Place";
import { useSelector } from "react-redux";

function Footer({bleuone}) {
  const { t } = useTranslation();
  const { pageSpecData } = useSelector((state) => state?.pages || {});
  const navigate = useNavigate();

  return (
    <footer
      className="footer-area bg-cover pd-top-110"
      style={{ background: bleuone ? "#03032d" : `url(${backgroundFoot})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="widget widget_about" style={{color: bleuone && "white"}}>
              <div className="thumb">
                <img src={logo2} alt="img" />
              </div>
              <div className="details">
                <div className="contact-ph-ml">
                  <div>
                    <CallIcon />
                    <span>+212 6 17 08 03 33</span>
                  </div>
                  <div>
                    <MailIcon />
                    <span>support@course-ai-influencer.com</span>
                  </div>
                  <div className="theadresse">
                    <PlaceIcon />
                    <span>
                      AVENUE ALLAL EL FASSI COMPLEX EL HABSSI RESIDENTIEL ET
                      PROFISIONNEL IMM A BUREAU N°07, Marrakech
                    </span>
                  </div>
                  
                  <div className="logopayzone">
                    <span>Ce site web est sécurisé 3D Secure</span>
                    <img src={box2} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="widget widget_nav_menu ps-xl-5">
              <h4 className="widget-title" style={{color: bleuone && "white"}}>{t("footer.main_pages")}</h4>
              <ul style={{ color: bleuone ? "white" : "#000" }}>
                <li  onClick={() => navigate("/")} style={{ color: bleuone ? "white" : "#000", cursor: "pointer" }}>{t("navbar.home")}</li>
                <li  onClick={() => navigate("/about")} style={{ color: bleuone ? "white" : "#000", cursor: "pointer" }}>{t("navbar.about")}</li>
                <a href={pageSpecData?.link} style={{ color: bleuone ? "white" : "#000", cursor: "pointer" }}>Mon compte</a>
                <li  onClick={() => navigate("/contactus")} style={{ color: bleuone ? "white" : "#000", cursor: "pointer" }}>{t("navbar.contact")}</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="widget widget_nav_menu">
              <h4 className="widget-title" style={{color: bleuone && "white"}}>{t("footer.customer_service")}</h4>
              <ul>
                <li style={{ color: bleuone? "white" : "#000" }}>
                  {t("footer.customer_service_description")}
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="widget widget-recent-post">
              <h4 className="widget-title" style={{color: bleuone && "white"}}>{t("footer.legal_pages")}</h4>
              <div className="widget widget_contact">
                <ul className="details privacycondi">
                  <li style={{color: bleuone && "white"}} onClick={() => navigate("/politique")}>
                    {t("footer.privacy_policy")}
                  </li>
                  <li style={{color: bleuone && "white"}} onClick={() => navigate("/cgu")}>
                    {t("footer.terms_of_use")}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div>
              <p
                style={{ margin: "auto", width: "fit-content", color: bleuone? "white" : "#000" }}
              >
                {t("footer.rights_reserved")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
