import React, { useEffect, useState } from "react";
import "./style.scss";
import Card from "react-bootstrap/Card";
import { Row, Col, Form } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiClient } from "../../actions/api";

function DeliveryInfo({ linkC, priceC }) {
  const generateToken = () => {
    return uuidv4();
  };
  const [newToken, setToken] = useState("");
  useEffect(() => {
    const tk = generateToken();
    if (newToken === "") {
      setToken(tk);
    }
  }, []);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
    amount: priceC, // Example amount
  });

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  function generateUUID() {
    return 'cs_' + ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => 
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !formData.email ||
      !formData.password ||
      !formData.confirmPassword ||
      formData.password !== formData.confirmPassword
    ) {
      toast.error("Veuillez remplir tous les champs correctement.");
      return;
    }
    setLoading(true);
    setError(null);

    const data = {
      customerId: generateUUID(),
      customerCountry: "MA",
      customerLocale: "en_US",
      price: formData.amount ? formData.amount : localStorage.getItem("priceC"),
      currency: "MAD",
      description: "An AI course",
      flowCompletionUrl: "https://course-ai-influencer.com/checkout",
      firstName: formData.firstName,
      lastName: formData.lastName,
      phone: formData.phone,
      email: formData.email,
      course_id: 8,
      course_link: linkC ? linkC : localStorage.getItem("linkC"),
      token: newToken,
      password: formData.password,
    };

    try {
      const emailCheckResponse = await apiClient().post("/check-email", {
        email: formData.email,
      });

      if (emailCheckResponse.data.exists) {
        toast.error("Cet email est déjà utilisé.");
        setLoading(false);
        return;
      }
      const response = await fetch(
        "https://course-ai-influencer.com/gestion/public/external-php/launch_paywall.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );


      // method 1
      // if (response.ok) {
      //   const responseText = await response.text();
      //   document.open();
      //   document.write(responseText);
      //   document.close();
      // } else {
      //   const responseData = await response.json();
      //   toast.error(responseData.message || "Une erreur s'est produite");
      // }

      // method 2
      if (response.ok) {
        const responseText = await response.text();
        
        // Create a temporary div to parse the form HTML from the response
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = responseText;
      
        // Extract the form from the parsed HTML
        const form = tempDiv.querySelector("form");
        
        if (form) {
          // Append the form to the document body
          document.body.appendChild(form);
      
          // Submit the form manually
          form.submit();
        } else {
          toast.error("Form not found in the response.");
        }
        
      } else {
        const responseData = await response.json();
        toast.error(responseData.message || "Une erreur s'est produite");
      }
         
      
    } catch (err) {
      toast.error(err.message || "Une erreur s'est produite");
    } finally {
      setLoading(false);
    }

    apiClient()
      .post(`/tokens/storeWithFalseStatus`, { token: newToken })
      .then((res) => {
        if(res?.data?.success){

        }
      });
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  return (
    <div className="delivery-card">
      <Card body>
        <div className="delivery-title">
          <span>Informations Client</span>
        </div>
        <div className="delivery-form">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <Form.Group className="form-groupe">
                  <Form.Label>Prénom</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="form-groupe">
                  <Form.Label>Nom</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="form-groupe">
                  <Form.Label>Téléphone</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="form-groupe">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="form-groupe">
                  <Form.Label>Mot de passe</Form.Label>
                  <div className="password-wrapper">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                    />
                    <span
                      className="password-icon"
                      onClick={toggleShowPassword}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="form-groupe">
                  <Form.Label>Répéter le mot de passe</Form.Label>
                  <div className="password-wrapper">
                    <Form.Control
                      type={showConfirmPassword ? "text" : "password"}
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                    />
                    <span
                      className="password-icon"
                      onClick={toggleShowConfirmPassword}
                    >
                      {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <button
              className="btn btn-primary submitbtn"
              type="submit"
              disabled={loading}
            >
              {loading ? "Loading..." : "Valider le paiement"}
            </button>
            {error && <p className="error">{error}</p>}
          </Form>
        </div>
      </Card>
      <ToastContainer />
    </div>
  );
}

export default DeliveryInfo;
