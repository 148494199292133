import React from 'react'
import { EnteteComp, PolitiqueContent } from '../../components'

function Politique() {
  return (
    <div>
        <PolitiqueContent />
    </div>
  )
}

export default Politique