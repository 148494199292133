import React from 'react';
import './style.scss'
const NotFoundPage = () => {
  return (
    <div className='pagenotfound' >
    <h1 style={{ fontSize: '2.5rem', color: '#333' }}>Page non trouvée</h1>
    <p style={{ fontSize: '1.2rem', color: '#666', marginTop: '10px' }}>Désolé, la page que vous recherchez n'existe pas.</p>
    </div>

  );
};

export default NotFoundPage;