import React, { useState } from "react";
import { EnteteComp } from "../../components";
import "./style.scss";
import img5 from "../../assets/img/about/5.png";
import { Link } from "react-router-dom";

function CoursePage() {
  // State to control which content to display
  const [selectedContent, setSelectedContent] = useState(0);

  // Function to handle click event on h3
  const handleTitleClick = (index) => {
    setSelectedContent(index);
  };

  const contentData = [
    {
      title: "Introduction à l'influence artificielle",
      content: [
        "Définition et contexte de l'influence artificielle.",
        "Évolution et impact sur les médias sociaux et la société.",
        "Avantages et défis de l'utilisation de l'influence artificielle.",
      ],
    },
    {
      title:
        "Utilisation d'outils d'IA pour créer des influenceurs/ses artificiels (IA)",
      content: [
        "Présentation des outils disponibles, notamment seaart.ai.",
        "Démonstrations pas à pas pour créer des avatars.",
        "Personnalisation et intégration des avatars dans le contenu.",
        "Exercices pratiques pour créer des influenceurs/ses artificiels (IA) authentiques et réels.",
      ],
    },
    {
      title: "Stratégies de contenu et de narration",
      content: [
        "Tendances de contenu et adaptation aux avatars virtuels.",
        "Création de personnages et de scénarios captivants.",
        "Utilisation efficace de la voix et de la personnalité des avatars.",
        "Études de cas sur des campagnes réussies.",
      ],
    },
    {
      title: "Techniques de monétisation",
      content: [
        "Diverses façons de gagner de l'argent avec l'influence artificielle.",
        "Publicité, parrainages et partenariats avec des marques.",
        "Utilisation des médias sociaux pour la monétisation.",
        "Considérations juridiques et fiscales.",
      ],
    },
    {
      title: "Gestion de la réputation en ligne et éthique",
      content: [
        "Transparence et authenticité dans l'influence artificielle.",
        "Gestion des attentes des followers et des risques potentiels.",
        "Éthique de l'utilisation de l'IA dans le marketing.",
        "Conformité aux réglementations en matière de publicité et de protection des consommateurs.",
      ],
    },
  ];

  return (
    <div>
      <EnteteComp title={"Cours détailles"} content={"Cours"} />
      <div className="course-area pd-top-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-12">
              <div className="td-sidebar course-sidebar pe-xl-4 mt-0">
                <div className="widget widget_catagory">
                  <ul className="catagory-items">
                    {contentData?.map((item, index) => (
                      <div key={index}>
                        <li onClick={() => handleTitleClick(index)}>
                          <Link to="#">
                            <span>
                              <svg
                                className="svg-inline--fa fa-angle-right fa-w-8"
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fa"
                                data-icon="angle-right"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 256 512"
                                data-fa-i2svg=""
                              >
                                <path
                                  fill="currentColor"
                                  d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"
                                ></path>
                              </svg>
                            </span>
                            {item?.title}
                          </Link>
                        </li>
                      </div>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="blog-details-page-content">
                <div className="single-blog-inner mb-0">
                  <div className="details p-0">
                    {
                      <div>
                        <h3>
                          {contentData[selectedContent]?.title}
                        </h3>
                          <ul className="p-0 m-0 single-check-inner mt-3 pt-3 mb-5">
                            {

                              contentData[selectedContent].content?.map((item, idx) => (
                                <li key={idx}>
                                <img src={img5} alt="img" />
                                {item}
                              </li>
                              ))
                            }
                          </ul>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CoursePage;
