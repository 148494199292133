import React from "react";
import "./style.scss";

function PolitiqueContent() {
  return (
    <div className="politique-content container">
      <p>
        Nous apprécions votre intérêt et vos préoccupations concernant la
        confidentialité de vos données sur Internet. Cette politique a été
        préparée pour vous aider à comprendre la nature des données que nous
        recueillons auprès de vous lorsque vous visitez notre site Web et
        comment nous traitons ces données personnelles.
      </p>
      <p>
        Nom de la société : Invest display <br />

        Adresse : AVENUE ALLAL EL FASSI COMPLEX EL HABSSI RESIDENTIEL ET PROFISIONNEL IMM A BUREAU N°07 MARRAKECH <br />

        Patante : 45311936<br />

        ICE : 003305770000021<br />

        RC : 145741 <br />

      </p>
      <span>Navigation</span>
      <p>Nous n'avons pas conçu ce site dans le but de collecter vos données
      personnelles à partir de votre ordinateur lors de la navigation sur ce
      site, mais seules les données fournies par vous seront utilisées en votre
      connaissance et volontairement.</p>
      <span>Adresse de Protocole Internet (IP)</span>
      <p>À tout moment où vous
      visitez un site Web, y compris ce site Web, le serveur hôte enregistrera
      votre adresse IP (Internet Protocol), la date et l’heure de la visite, le
      type de navigateur Internet que vous utilisez et l’URL de tout site Web
      auquel vous vous référez sur le web.</p>
      <span>Balayages Réseau</span>
      <p>
      Les sondages que nous réalisons directement sur le réseau nous permettent
      de collecter des données spécifiques telles que les données qui vous sont
      demandées concernant votre avis et ressenti sur notre site.
      </p>
      <span>Divulgation d’Informations</span>
      <p>
      Nous maintiendrons à tout moment la confidentialité et la confidentialité
      de toutes les données personnelles que nous obtenons. Ces informations ne
      seront pas divulguées sauf si une loi l’exige ou si nous croyons de bonne
      foi qu’une telle action sera nécessaire ou souhaitable pour se conformer à
      la loi, ou pour défendre ou protéger les droits de propriété de ce site ou
      de ses ayants droit.
      </p>
      <span>
        Données Nécessaires à la Réalisation des Transactions que Vous Demandez
      </span>
      <p>
      Lorsque nous avons besoin de données vous concernant, nous vous
      demanderons de nous les fournir de votre plein gré. Ces informations nous
      aideront à vous contacter et à répondre à vos demandes dans la mesure du
      possible. Les données que vous fournissez ne seront jamais vendues à des
      tiers dans le but de les commercialiser à son profit sans votre
      consentement écrit préalable, sauf si cela est fait au motif qu’elles font
      partie de données collectives utilisées à des fins statistiques et de
      recherche sans inclure toute donnée pouvant être utilisée pour vous
      identifier.
      </p>
      <span>Lorsque Vous Nous Contactez</span>
      <p>
      Toutes les données fournies par vous seront traitées de manière
      confidentielle. Les formulaires soumis directement sur le réseau
      nécessitent de fournir des données qui nous aideront à améliorer notre
      site. Les données que vous fournissez seront utilisées pour répondre à
      toutes vos demandes, commentaires ou demandes de ce site ou de l’un de ses
      sites affiliés.
      </p>
      <span>Divulgation d’Informations à des Tiers</span>
      <p>Nous ne vendrons, n’échangerons, ne louerons ni ne divulguerons aucune
      information au profit de tiers en dehors de ce site ou de ses sites
      affiliés. Les informations ne seront divulguées qu’en cas d’ordre de le
      faire par toute autorité judiciaire ou réglementaire.</p>
      <span>
        Modifications de la Politique de Confidentialité et de Confidentialité
        des Informations
      </span>
      <p>
      Nous nous réservons le droit de modifier les termes et conditions de la
      politique de confidentialité et la confidentialité des informations si
      nécessaire et le cas échéant. Les modifications seront mises en œuvre ici
      ou sur la page principale de la politique de confidentialité, et vous
      serez constamment informé des données que nous avons obtenues, de la
      manière dont nous les utiliserons et qui lui fournira ces données.
      </p>
      <span>Contactez-Nous</span>
      <p>Vous pouvez nous contacter en cas de besoin en cliquant sur le lien
      Contactez-nous disponible dans les liens de notre site Web ou en visitant
      notre site web : https://course-ai-influencer.com/ Enfin, vos
      préoccupations et préoccupations concernant la confidentialité et la
      confidentialité des données sont une question de la plus haute importance
      pour nous. Nous espérons que cet objectif sera atteint grâce à cette
      politique.</p>
    </div>
  );
}

export default PolitiqueContent;
