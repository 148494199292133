import React from 'react'
import { CguContent, EnteteComp } from '../../components'

function CguPage() {
  return (
    <div>
        <CguContent />
    </div>
  )
}

export default CguPage