import React from 'react'
import imgbck from "../../assets/img/bg/9.png";
import { Link } from 'react-router-dom';
import { ArrowForwardIos } from '@mui/icons-material';

function EnteteComp({title, content}) {
  return (
    <div className="breadcrumb-area bg-cover" style={{backgroundImage: `url(${imgbck})`}}>
        <div className="container">
            <div className="breadcrumb-inner text-center">
                <h2 className="page-title">{title}</h2>
                <ul className="page-list">
                    <li><Link to="/">{'Accueil'}</Link></li>
                    <ArrowForwardIos />
                    <li>{content}</li>
                </ul>
            </div>
        </div>
    </div>
  )
}

export default EnteteComp