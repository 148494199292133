import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const TrackPageViews = () => {
    const location = useLocation();
  
    useEffect(() => {
      // Notify Google Tag Manager of the route change
      if (window.gtag) {
        window.gtag('config', 'AW-16817837434', {
          page_path: location.pathname,
        });
      }
    }, [location]);
  
    return null;
  };
  
export default TrackPageViews;
  